'use client';

import {
  useCallback, useState, useRef, MouseEvent,
} from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { observer } from 'mobx-react-lite';
import debounce from 'lodash/debounce';

import Button from '@sympla/sympla-components/Button';

import IconSearch from 'assets/icons/ic-search.svg';

import useSearch from 'hooks/useSearch';
import { useGetPathWithLocation } from 'hooks/useGetPathWithLocation';
import locationStore from 'stores/LocationStore';
import { createLinkToRedirect } from 'utils/createLinkToRedirect';
import { useLastSearches } from 'hooks/useLastSearches';
import SearchListDropdown from './components/SearchList';

import * as styles from './Search.css';

const options = [
  { value: 'Eventos', tab: 'eventos' },
  { value: 'Locais', tab: 'locais' },
  { value: 'Produtores', tab: 'produtores' },
];

function Search({ isNavbarCollapsed = false }:{isNavbarCollapsed?: boolean}) {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const searchParams = useSearchParams();
  const searchStore = useSearch();
  const getPathWithLocation = useGetPathWithLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const searchText = searchParams?.get('s') as string | undefined;
  const [text, setText] = useState(searchText || '');
  const { updateLastSearches, lastSearches } = useLastSearches();

  const fillCitiesDebounced = useCallback(debounce(searchStore.executeSearch, 800), []);

  const handleShowMore = useCallback(() => {
    setIsDropdownOpen(false);
    window.location.href = createLinkToRedirect({
      param: `?s=${text}`,
      routeType: 'search-bar-default-filter',
      userLocation: locationStore?.selectedLocation,
    });
  }, [text, locationStore?.selectedLocation?.slug]);

  function handleSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsDropdownOpen(false);
    updateLastSearches(text);
    handleShowMore();

    const path = getPathWithLocation({ search: true, path: 'eventos', query: { s: text } });
    if (path) router.push(path);
  }

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setText(event.target.value);
    fillCitiesDebounced(event.target.value);
  }

  function onFocusSearch() {
    setIsDropdownOpen(true);
    if (searchText) {
      searchStore.executeSearch(searchText);
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const form = formRef.current;
    if (form && !form.contains(event.relatedTarget as Node)) {
      setIsDropdownOpen(false);
    }
  }

  function handleFormClick() {
    setIsDropdownOpen(true);
    inputRef.current?.focus();
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && text.trim().length > 0) {
      setIsDropdownOpen(false);

      handleShowMore();

      const path = getPathWithLocation({ search: true, path: 'eventos', query: { s: text } });
      if (path) router.push(path);
    } else if (event.key && !isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  }

  function handleCancelButton(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    inputRef.current?.blur();
    setIsDropdownOpen(false);
  }

  return (
    <div className={`${styles.searchWrapper} ${isNavbarCollapsed && styles.collapsedSearchWrapper}`}>
      <form
        ref={formRef}
        className={`${styles.searchForm} ${isNavbarCollapsed && styles.collapsedSearchForm}`}
        onSubmit={handleSearch}
        onClick={handleFormClick}
        onKeyDown={(e) => e.key === 'Enter' && handleFormClick()}
        role="presentation"
      >
        <IconSearch width={24} height={24} />

        <input
          ref={inputRef}
          className={styles.searchInput}
          onFocus={onFocusSearch}
          onBlur={handleBlur}
          placeholder="Pesquisar eventos, shows, teatros, cursos"
          type="text"
          onChange={handleTextChange}
          value={text}
          onKeyDown={handleKeyDown}
        />
        <Button variant="text" type="submit" className={text ? styles.searchButton : styles.hiddenButton}>
          Procurar
        </Button>

        {isDropdownOpen && (
          <Button variant="text" type="button" className={styles.cancelButton} onClick={(e) => handleCancelButton(e)}>
            Cancelar
          </Button>
        )}
      </form>
      <SearchListDropdown
        updateLastSearches={updateLastSearches}
        lastSearches={lastSearches}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        handleShowMore={handleShowMore}
        options={options}
        text={text}
        form={formRef.current}
        userLocation={locationStore?.selectedLocation}
      >
        <div />
      </SearchListDropdown>
    </div>
  );
}

export default observer(Search);
