import { setCookie } from 'cookies-next';
import { GEOLOCATION_NOTIFICATION, GEOLOCATION_PERMISSION } from 'constants/browserStorageNames';

export function setGeoLocationPermissionSessionStorageWL(value: number | boolean) {
  sessionStorage?.setItem(GEOLOCATION_PERMISSION, JSON.stringify({ status: value }));
}

export function setGeoLocationNotificationCookieWL(value: string) {
  const currentDate = new Date();
  const options = {
    domain: process.env.NODE_ENV === 'production' ? '.sympla.com.br' : 'localhost',
    expires: new Date(currentDate.setMonth(currentDate.getMonth() + 1)),
    path: '/',
  };
  setCookie(GEOLOCATION_NOTIFICATION, value, options);
}
