import { ReactNode, useEffect, useState } from 'react';
import * as styles from './UserSection.css';

interface UserSectionProps {
    fullName: string
    email: string
    avatar: ReactNode
    isMobile?: boolean
}

function UserSection({
  fullName, email, avatar, isMobile = false,
}: UserSectionProps) {
  const [device, setDevice] = useState<'desktop' | 'mobile'>('desktop');

  useEffect(() => {
    if (isMobile) {
      return setDevice('mobile');
    }
    return setDevice('desktop');
  }, [isMobile]);

  return (
    <div className={styles.userContainer}>
      <div className={styles.avatar[device]}>
        {avatar}
      </div>
      <div className={styles.userNameContainer}>
        <span className={styles.userName[device]}>{fullName}</span>
        <span className={styles.userEmail[device]}>{email}</span>
      </div>
    </div>
  );
}

export default UserSection;
