import { isServer } from 'utils/misc';
import {
  createCipheriv,
  createDecipheriv,
} from 'crypto';

function encryptJSON() {
  const iv = 'qwertyuioplkjhdg';
  const hash = 'qwertyuioplkjhdgqwertyuioplkjhdg';
  const fallback = 'aes-256-ctr';

  const encode = (rawState: string) => {
    let stateEncoded = null;
    const cipher = createCipheriv(String(process?.env?.KEY_ENCODE_DATA || fallback), hash, iv);
    stateEncoded = cipher.update(JSON.stringify(rawState), 'utf8', 'base64');
    stateEncoded += cipher.final('base64');
    return stateEncoded;
  };

  const decode = (encodedState: string) => {
    try {
      const decipher = createDecipheriv(String(process?.env?.KEY_ENCODE_DATA || fallback), hash, iv);
      let decodedState = decipher.update(encodedState, 'base64', 'utf8');
      decodedState += decipher.final('utf8');
      decodedState = JSON.parse(decodedState);
      return decodedState;
    } catch (error) {
      return false;
    }
  };

  return {
    encode,
    decode,
  };
}

function _getCookies() {
  if (isServer()) return {};

  return Object.fromEntries(
    document.cookie?.split('; ')?.map((x) => x?.split('=')),
  );
}

export function getBuyerEmailDecodedClient() {
  const cookies = _getCookies();
  const doSomeMagic = encryptJSON();

  if (cookies.s_buyer_status) {
    return doSomeMagic.decode(cookies.s_buyer_status);
  }

  return false;
}

export function saveBuyerEmailEncodedClient(userEmail: string) {
  if (userEmail === '') return false;
  if (typeof userEmail !== 'string') throw new Error('Param must be a string');

  const doSomeMagic = encryptJSON();
  const now = new Date();
  now.setFullYear(now.getFullYear() + 1);

  document.cookie = `s_buyer_status=${doSomeMagic.encode(
    userEmail,
  )}; expires=${now}; path=/; domain=${process.env.NODE_ENV === 'production' ? '.sympla.com.br' : 'localhost'}`;

  return true;
}
