import { useEffect, useState } from 'react';

export function useLastSearches() {
  const [lastSearches, setLastSearches] = useState<string[]>([]);

  function updateLastSearches(text: string) {
    if (text) {
      const filteredLastSearches:string[] = lastSearches.filter((item: string) => (
        item.trim().toLocaleLowerCase() !== text.trim().toLocaleLowerCase()
      ));
      filteredLastSearches.unshift(text);
      setLastSearches(filteredLastSearches.slice(0, 5));
    }
  }

  function getLastSearches() {
    try {
      const storage = localStorage.getItem('lastSearchesStorage');
      if (storage) {
        const parsedStorage = JSON.parse(storage);
        setLastSearches(Array.isArray(parsedStorage) ? parsedStorage : []);
      } else {
        setLastSearches([]);
      }
    } catch (error) {
      setLastSearches([]);
    }
  }

  useEffect(() => {
    getLastSearches();
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem('lastSearchesStorage', JSON.stringify(lastSearches));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Erro ao salvar pesquisas recentes:', error);
    }
  }, [lastSearches]);

  return {
    lastSearches,
    setLastSearches,
    updateLastSearches,
  };
}
