import { setCookie } from 'cookies-next';
import { CANT_CACHE_LOCATION, USER_LOCATION } from 'constants/browserStorageNames';
import { Location } from 'location.d';

type SetLocationCookiesParams = {
  userLocation?: Location,
  cantCacheLocation?: boolean,
}

export function setLocationCookies({
  userLocation,
  cantCacheLocation,
}: SetLocationCookiesParams) {
  const currentDate = new Date();
  const remoteOptions = {
    domain: '.sympla.com.br',
    expires: new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)),
    path: '/',
    encode: (cookie: string) => cookie,
  };
  const localOptions = {
    domain: 'localhost',
    expires: new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)),
    path: '/',
    encode: (cookie: string) => cookie,
  };
  if (userLocation) {
    setCookie(USER_LOCATION, { ...userLocation, city: encodeURI(userLocation.city) }, remoteOptions);
    setCookie(USER_LOCATION, { ...userLocation, city: encodeURI(userLocation.city) }, localOptions);
  }
  if (cantCacheLocation) {
    setCookie(CANT_CACHE_LOCATION, cantCacheLocation, remoteOptions);
    setCookie(CANT_CACHE_LOCATION, cantCacheLocation, localOptions);
  }
}
