import { GEOLOCATION_PERMISSION } from 'constants/browserStorageNames';
import { ANYWHERE_LOCATION_JSON, NEAR_TO_YOU_LABEL } from 'constants/index';
import { Location } from 'location.d';
import { setGeoLocationPermissionSessionStorageWL } from 'utils/whiteLionCompatibilityFunctions';
import { setItemOnStorage } from 'utils/misc';
import { setLocationCookies } from './setLocationCookies';

export enum GeolocationStatus {
  ACCEPTED = '200',
  UNKNOWN_ERROR = '0',
  PERMISSION_DENIED = '1',
  POSITION_UNAVAILABLE = '2'
}

export function getGeoLocation(setSelectedLocation: (location: Location) => void) {
  if (!navigator?.geolocation) return;

  const success = ({ coords }: GeolocationPosition) => {
    setGeoLocationPermissionSessionStorageWL(Number(GeolocationStatus.ACCEPTED));
    setItemOnStorage(GEOLOCATION_PERMISSION, GeolocationStatus.ACCEPTED);

    const payload = {
      city: NEAR_TO_YOU_LABEL,
      coordinates: { lat: coords?.latitude, lon: coords?.longitude },
      slug: '',
    };
    setLocationCookies({
      userLocation: payload,
      cantCacheLocation: true,
    });
    setSelectedLocation(payload);
  };

  const error = (e: GeolocationPositionError) => {
    setGeoLocationPermissionSessionStorageWL(e?.code);
    setItemOnStorage(GEOLOCATION_PERMISSION, String(e?.code));

    setLocationCookies({
      userLocation: ANYWHERE_LOCATION_JSON,
      cantCacheLocation: false,
    });
    setSelectedLocation(ANYWHERE_LOCATION_JSON);
  };

  const options = {
    maximumAge: 5 * 60 * 1000,
    timeout: 10 * 1000,
  };

  navigator?.geolocation?.getCurrentPosition(success, error, options);
}
