'use client';

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';

import Modal from '@sympla/sympla-components/Modal';
import { IconPlace, IconLocalizationFill as IconArrowLocation } from '@sympla/sympla-components/ActionIcons';
import { IconArrowDown } from '@sympla/sympla-components/NavigationIcons';
import { Location } from 'location';
import { setGeoLocationNotificationCookieWL } from 'utils/whiteLionCompatibilityFunctions';
import { getItemFromStorage, setItemOnStorage } from 'utils/misc';
import { NEAR_TO_YOU_LABEL } from 'constants/index';
import { GEOLOCATION_NOTIFICATION, GEOLOCATION_PERMISSION } from 'constants/browserStorageNames';

import locationStore from 'stores/LocationStore';
import { Loading } from 'components/new-components/Loading';
import { GeolocationStatus, getGeoLocation } from '../utils/getGeoLocation';

import * as styles from './LocationButton.css';

const CloseButtonModal = dynamic(() => import('components/new-components/Shared/CloseButtonModal/CloseButtonModal'), { ssr: false });
const GeolocationModal = dynamic(
  () => import('../GeolocationModal/GeolocationModal'),
  {
    ssr: false,
    loading: () => <Loading isNotFullScreen />,
  },
);
const LocationModal = dynamic(
  () => import('../LocationModal/LocationModal'),
  {
    ssr: false,
    loading: () => <Loading isNotFullScreen />,
  },
);

const CLOSED_LABEL = 'closed';

function LocationButton({ selectedLocation, variant = 'default', isNavbarCollapsed = false }: {
  selectedLocation: Location,
  variant?: 'default' | 'primary';
  isNavbarCollapsed?: boolean;
}) {
  locationStore.hydrate({
    cities: [],
    selectedLocation,
  });

  useEffect(() => {
    const isGeolocationAccepted = getItemFromStorage(GEOLOCATION_PERMISSION) === GeolocationStatus.ACCEPTED;
    const isNearToYouSelected = locationStore.selectedLocation?.city === NEAR_TO_YOU_LABEL;
    const showGeolocationNotification = !isNearToYouSelected && getItemFromStorage(GEOLOCATION_NOTIFICATION) !== CLOSED_LABEL;

    locationStore.setIsGeolocationVisible(showGeolocationNotification);

    if (isGeolocationAccepted && isNearToYouSelected) getGeoLocation(locationStore.setSelectedLocation);
  }, []);

  function handleGeoLocationClick() {
    locationStore.setIsGeolocationVisible(false);
    locationStore.setIsGeolocationModalVisible(true);
    setGeoLocationNotificationCookieWL(CLOSED_LABEL);
    setItemOnStorage(GEOLOCATION_NOTIFICATION, CLOSED_LABEL);
    if (window?.dataLayer) {
      window.dataLayer.push({
        stckEventCategory: 'UserGeoLocation',
        stckEventLabel: '',
        stckEventAction: 'view',
        event: 'stck',
      });
    }
  }

  return (
    <div className={`${styles.container[variant || 'default']} ${isNavbarCollapsed && styles.collapsedContainer}`}>
      <button
        type="button"
        className={`${styles.button} ${isNavbarCollapsed && styles.collapsedButton}`}
        onClick={() => locationStore.setIsLocationModalVisible(true)}
      >
        <IconPlace width={20} height={20} />
        <div className={styles.locationName}>{locationStore.selectedLocation?.city}</div>
        <IconArrowDown width={24} height={24} />
      </button>

      <div
        className={styles.geolocationContainer}
        style={{ display: locationStore.isGeolocationVisible ? 'flex' : 'none' }}
      >
        <div
          className={`${styles.geolocationContent} ${isNavbarCollapsed ? styles.geolocationContentCollapsed : ''}`}
          role="button"
          tabIndex={0}
          onClick={handleGeoLocationClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              handleGeoLocationClick();
            }
          }}
        >
          <IconArrowLocation width={20} height={20} />
          <span>Descubra o que fazer perto de você</span>
          <CloseButtonModal
            ariaLabel="Fechar notificação de localização"
            iconSize={10}
            top={6}
            right={8}
            onClick={(e) => {
              e?.stopPropagation();
              locationStore.setIsGeolocationVisible(false);
              setGeoLocationNotificationCookieWL(CLOSED_LABEL);
              setItemOnStorage(GEOLOCATION_NOTIFICATION, CLOSED_LABEL);
            }}
          />
        </div>
      </div>

      <Modal
        title={false}
        width={350}
        open={locationStore.isGeolocationModalVisible}
        onClose={() => locationStore.setIsGeolocationModalVisible(false)}
        accessibilityTitle="Modal de Localização"
      >
        <GeolocationModal />
      </Modal>

      <Modal
        title={false}
        width={520}
        open={locationStore.isLocationModalVisible}
        onClose={() => locationStore.setIsLocationModalVisible(false)}
        accessibilityTitle="Modal de Localização"
      >
        <LocationModal />
      </Modal>
    </div>
  );
}

export default observer(LocationButton);
