export const citiesCollectionList = [
  'sao-paulo-sp',
  'belo-horizonte-mg',
  'rio-de-janeiro-rj',
  'porto-alegre-rs',
  'brasilia-df',
  'salvador-ba',
  'curitiba-pr',
  'recife-pe',
  'florianopolis-sc',
  'campinas-sp',
  'fortaleza-ce',
  'goiania-go',
];
