import IconCategoryParty from 'assets/icons/ic-category-party.svg';
import IconCategoryStandUp from 'assets/icons/ic-category-stand-up.svg';
import IconCategorySports from 'assets/icons/ic-category-sports.svg';

export const categoriesFilters = [
  {
    label: 'Festas & Shows',
    icon: <IconCategoryParty width={20} height={20} />,
    link: '/eventos/show-musica-festa',
  },
  {
    label: 'Stand-up Comedy',
    icon: <IconCategoryStandUp width={20} height={20} />,
    link: '/eventos/stand-up-comedy',
  },
  {
    label: 'Esportes',
    icon: <IconCategorySports width={20} height={20} />,
    link: '/eventos/esportivo',
  },
];
