'use client';

import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import dynamic from 'next/dynamic';
import { useSearchContentByLocation } from 'hooks/useSearchContentByLocation';
import { useGetPathWithLocation } from 'hooks/useGetPathWithLocation';
import locationStore from 'stores/LocationStore';
import { searchEventStore } from 'stores/SearchContentBaseStore';
import { PageType } from 'constants/enums/search';
import { ANYWHERE_LOCATION, NEAR_TO_YOU_LABEL } from 'constants/index';
import { getGeolocationModalData } from '../utils/getGeolocationModalData';
import * as styles from './GeolocationModal.css';

const Button = dynamic(() => import('@sympla/sympla-components/Button'), {
  ssr: false,
});

const IconArrowLocation = dynamic(() => import('@sympla/sympla-ui/lib/icons/v2/action/iconLocalizationFill'), {
  ssr: false,
});

const CloseButtonModal = dynamic(() => import('components/new-components/Shared/CloseButtonModal/CloseButtonModal'), {
  ssr: false,
});

function GeolocationModal() {
  const route = useRouter();
  const searchContent = useSearchContentByLocation();
  const getPathWithLocation = useGetPathWithLocation();

  const data = getGeolocationModalData((location) => {
    const changeCity = locationStore?.selectedLocation?.slug !== location.slug && !!locationStore?.selectedLocation?.slug;
    locationStore.setSelectedLocation(location);
    const noCity = [ANYWHERE_LOCATION, NEAR_TO_YOU_LABEL].includes(location?.city);
    if (searchEventStore.typePage === PageType.COLLECTION && noCity) {
      window.location.reload();
      return;
    }
    // TODO: searchContent should change when home and other pages are finished
    searchContent(window.location.search);
    const url = getPathWithLocation({ location, changeCity });
    if (url) route.push(url);
  });

  return (
    <div className={styles.container}>
      <CloseButtonModal
        ariaLabel="Fechar modal de localização"
        iconSize={24}
        top={8}
        right={8}
        onClick={() => locationStore.setIsGeolocationModalVisible(false)}
      />
      <IconArrowLocation width={24} height={24} />
      <p className={styles.title}>{data.title}</p>
      <p className={styles.text}>{data.text}</p>
      <div className={styles.buttonsWrapper}>
        <Button
          size="small"
          onClick={() => {
            locationStore.setIsGeolocationModalVisible(false);
            data.onAccept();
          }}
        >
          {data.buttonLabel}
        </Button>
        <Button
          size="small"
          variant="text"
          onClick={() => {
            locationStore.setIsGeolocationModalVisible(false);
            data.onCancel();
          }}
        >
          Agora não
        </Button>
      </div>
    </div>
  );
}

export default observer(GeolocationModal);
