import { GEOLOCATION_PERMISSION } from 'constants/browserStorageNames';
import { ANYWHERE_LOCATION_JSON } from 'constants/index';
import { Location } from 'location.d';
import { setGeoLocationPermissionSessionStorageWL } from 'utils/whiteLionCompatibilityFunctions';
import { getItemFromStorage, setItemOnStorage } from 'utils/misc';
import { GeolocationStatus, getGeoLocation } from './getGeoLocation';
import { setLocationCookies } from './setLocationCookies';

export function getGeolocationModalData(setSelectedLocation: (location: Location) => void) {
  const geolocationPermissionStatus = getItemFromStorage(GEOLOCATION_PERMISSION);

  const data = {
    [GeolocationStatus.ACCEPTED]: {
      title: 'Atualizar sua localização',
      text: 'Atualize a sua localização para encontrar as melhores experiências ao seu redor.',
      buttonLabel: 'Atualizar',
      onAccept: () => {
        if (window?.dataLayer) {
          window.dataLayer.push({
            stckEventCategory: 'UserGeoLocation',
            stckEventLabel: '',
            stckEventAction: 'update',
            event: 'stck',
          });
        }
        getGeoLocation(setSelectedLocation);
      },
      onCancel: () => null,
    },
    [GeolocationStatus.UNKNOWN_ERROR]: {
      title: 'Descubra o que fazer perto de você',
      text: 'Ocorreu um erro no momento da identificação da sua localização, deseja tentar novamente?',
      buttonLabel: 'Permitir',
      onAccept: () => {
        getGeoLocation(setSelectedLocation);
      },
      onCancel: () => {
        setGeoLocationPermissionSessionStorageWL(false);
        setItemOnStorage(GEOLOCATION_PERMISSION, '');
        setLocationCookies({
          userLocation: ANYWHERE_LOCATION_JSON,
        });
        setSelectedLocation(ANYWHERE_LOCATION_JSON);
      },
    },
    [GeolocationStatus.PERMISSION_DENIED]: {
      title: 'Descubra o que fazer perto de você',
      text: `Verifique as configurações do seu navegador e permita que a Sympla identifique a sua localização para 
        encontrar as melhores experiências ao seu redor.`,
      buttonLabel: 'Saiba mais',
      onAccept: () => {
        setGeoLocationPermissionSessionStorageWL(false);
        setItemOnStorage(GEOLOCATION_PERMISSION, '');
        window.open('https://support.google.com/chrome/answer/142065');
      },
      onCancel: () => {
        setLocationCookies({
          userLocation: ANYWHERE_LOCATION_JSON,
        });
        setSelectedLocation(ANYWHERE_LOCATION_JSON);
      },
    },
    [GeolocationStatus.POSITION_UNAVAILABLE]: {
      title: 'Descubra o que fazer perto de você',
      text: 'Atualize a sua localização para encontrar as melhores experiências ao seu redor.',
      buttonLabel: 'Permitir',
      onAccept: () => {
        getGeoLocation(setSelectedLocation);
      },
      onCancel: () => {
        setGeoLocationPermissionSessionStorageWL(false);
        setItemOnStorage(GEOLOCATION_PERMISSION, '');
        setLocationCookies({
          userLocation: ANYWHERE_LOCATION_JSON,
        });
        setSelectedLocation(ANYWHERE_LOCATION_JSON);
      },
    },
    default: {
      title: 'Descubra o que fazer perto de você',
      text: 'Permita que a Sympla identifique a sua localização para encontrar as melhores experiências ao redor.',
      buttonLabel: 'Permitir',
      onAccept: () => {
        if (window?.dataLayer) {
          window.dataLayer.push({
            stckEventCategory: 'UserGeoLocation',
            stckEventLabel: '',
            stckEventAction: 'accept',
            event: 'stck',
          });
        }
        getGeoLocation(setSelectedLocation);
      },
      onCancel: () => {
        if (window?.dataLayer) {
          window.dataLayer.push({
            stckEventCategory: 'UserGeoLocation',
            stckEventLabel: '',
            stckEventAction: 'notAccept',
            event: 'stck',
          });
        }
      },
    },
  };

  return data?.[geolocationPermissionStatus as keyof typeof data] ?? data.default;
}
