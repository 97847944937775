'use client';

import { LocationHydration } from 'stores/LocationStore';
import Search from 'components/new-components/Search/Search';

import * as styles from './NewHeaderContext.css';
import HeaderFilters from './components/HeaderFilters/HeaderFilters';

function NewHeaderContext({
  locationHydration,

  isNavbarCollapsed = false,
}: {
  locationHydration: LocationHydration;
  isNavbarCollapsed?: boolean;
}) {
  return (
    <div className={`${styles.container} ${isNavbarCollapsed && styles.colapsedContainer}`}>
      <Search isNavbarCollapsed={isNavbarCollapsed} />
      <HeaderFilters locationHydration={locationHydration} isNavbarCollapsed={isNavbarCollapsed} />
    </div>
  );
}

export default NewHeaderContext;
