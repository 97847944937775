import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B2WyW7bSBBA7%2F6KAnhJADnoqt7I8omk1NcAMx8Q0BZleZNlSfGSQf59UDIymC51hATIUTw%2BsBZWF9GPN4%2BPO%2FjnDOD8%2FEt4Xu52T4aB1q8X%2F0fI4BQihqCQZagVcgyok3kG1KGBgXSFyGB1bM0QnFGwYaithgMDotH0kgH9Ab1i%2BLweV%2FD3sNpOYDustufbcXOzyF6aM%2Fw13I8vw9tPXxkZUOdeFCZwzYD6a5eFqdwwoJ7oLQPpGncMpGvcFyb6wEA636ow5UcGq2PXDFb398TgdOyGwesaW4age94xWGMy9JXBKfQskTl6YYgKvTLUCr0x5OBbYavN4VojHu410uFioy2cK7rCIaIvnCKWFh5LG4%2B1TEXDhsEd5BwY%2FEH1S4ZwkPNKfiMN5wzx4M1RRqvhgsEArl9lomBgc305fCA%2FAYsTcGYC5hP6j3nItYTI6%2FKD%2FCRGhSwlJEgV98tlbvZlpIQsazmIVMwtQ5OTO4ZqDHEkyvk9Q7XAK2fy3x4fGCqKwxjylcMVQ2VMTNOQ88c9b2JKOV9LnrZNmj8xVDOfnOYbyRMb533OtwwVxo5Cm%2FMdQ%2BViP63V936V%2FH2aJpvzZ4Yq1raZzXL%2BwlDV0Xcp5vyVoWpd3aYu528MVfLJ6v6%2FST8ptoT51WIYqhCaRHn%2FhFI39J3tc07v%2BTvVJ1n5LuMS5vMnJzyFmvLzIi95bB2dy3kQPktB54%2F8Y7%2F8BNC5CXhXWkuqGappQ4amOW8ksbG1LjgIb0KIqsHL90ZIHRRd%2FdeImUA0E2hCsY%2B5xO%2BfnI%2FvA4ypzvlC%2Bp7OzEz1d81QdU3X9%2FkPQks5IFf3Tb4AdCMH3fsY1MHdyqI2mKi%2BOPt%2BxuIin%2BbD5u58txwfxpOWnLTkpCUnLTlpyZ%2FXEt93vb6Oj2lJSWNESwySvr32WhL9zLRKD45pSUljREuMt1bpwV5Letv5lN%2BmR7WkpDGiJTRD8oqLlrT9tI%2F5%2FI9qSUljREuwpdqo21C0JMWOospzTEtKGiNa4hF7VO%2F%2FlpakadO16ho%2FpiUljREt8d70qAbwO1piBkTMF3ivJWjJ2OFQS3BOczf%2BupbM56MZ1feIliz8Ii6U9oiWLPbPxdn3fwETbm57IhEAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fnew-components%2FSession%2FUserSpace%2FUserSpace.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VWbW%2BjOBD%2B3l8x2uqkIq0ryAtJqa7aPan3J1arlQMGfDU2Z5sk7an%2F%2FYQhKRibtF3xAcnjmXnm8bzd%2Foq2d8djvA3hvysALTFXVFPBE8CMwe1S3V8BZFTVDD8nkDNybA8wowVHVJNKJZASrom8v3q9uj1Zi4y1D6stjFqNs4zyIoE9ljcI%2FYr3pdb%2FRsvAOlgFI%2BUQHmDXaC34VxgA8RziDyAEyAXXSNEXYoEqgrP0QGhRaku%2BN3JNjhplJBUSd9RywUkrSAUTMoHrTbSJt2F7UuDajnvhCxPUvnCG6hDg9syEnFPGLBcLamAeaKZL2%2FvaiCrKkVO8CubooYGVFQ%2BAb9W%2BQLWkFZbPSGkpnojBVmNduqOZ0xiEZHK3xpJwPeDWFWhnwyWzwSY5lUojkSP9XBPjrMKyoBxJx2tHy7GJlTvHPG%2FsTb6dkBmRCUT1EZRgNIPrx7vH749%2F3fuLJbKL5fLBMnhzhiTOaKMsqxs7vFNOebke3X4AVWPuoPTMUFcXA6XYyEWjGeUkgcWZgTF0YaD315DIc0W0uT0ytjHGLgX4Wb6NJk6fCikanqFTaf%2B9bj9veXUeS1fv6GXvbVH%2FNErT%2FBmlgmvCtbO%2FbuEBMrqHH1Iw8ueXivDmy8%2Bu54pT25eEYU33xCp73GjRHjnzPg5G1IQjp3f%2ByTK4hYePsxNai%2BpjL%2BAcD5%2BYQThh2MpPC9UkS%2FF5nng6%2FeDuztxztACrd6Tu3tH%2BUUYlSTv7qWBNxUea2bAkr1fRKl5Hvz%2FDKnw8pUK0MZUFIPZE5kwcEihplhF%2BnnVvAsIYrRU1W8ShpJogVeOUtMQcJK5HwMkI%2BHq53saLOeD5BeB6zGg%2ByUO7XCH07TsfbdlT9j1ZuwggCjs2T%2ByG4R%2BXGrG1EuRJ2TLe5eqkBY01iYGdNlK1slrQSQHkc6tC32pdpnvRIFFm17X8c2PdObuN64HtXt9TK9armWtKY6ln2%2BjME7a%2Bv1UkoxhUKgnhgHkGNwMmVou4PgYG1fxq2m3AZ1%2BIkdxO6xdD8%2Btln8tw4vN3l8d3APMMulWPegBmNTDry5pyqrbpVZxu4h6De6KepJ6XDN%2FJ7GYxZbZbF7nQN29vGfRIrf3mPS6iKJz6WPrtjWj1LloD1R0T6dMclrfR7wqXzoT2PwIQzutUDgAA%22%7D"
export var avatar = '_189xx687';
export var buttonsContainer = '_189xx680';
export var customDropdown = '_189xx689';
export var customDropdownSection = {dropdownUserSection:'_189xx68b _189xx68a',dropdownLinkSection:'_189xx68h _189xx68a'};
export var dropdownContainer = '_189xx688';
export var dropdownLinkItem = '_189xx68f';
export var linksContainer = '_189xx681';
export var linksContainerCollapsed = '_189xx683';
export var mobileOnly = '_189xx68i';
export var myTickets = '_189xx682';
export var userButtonDropdown = {'default':'_189xx684',focus:'_189xx686 _189xx684'};
export var userEmail = '_189xx68e _189xx68d';
export var userName = '_189xx68d';
export var userNameContainer = '_189xx68c';