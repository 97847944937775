'use client';

import { LocationHydration } from 'stores/LocationStore';
import { CLASS_TRACKING_GTM, MATRIX_TYPE } from 'constants/index';
import { createPropsToTrackingMatrix } from 'components/Integration/createPropsToTrackingMatrix';
import { getMainUrl } from 'utils/misc';
import LocationButton from '../../../LocationButton/LocationButton';
import * as styles from './HeaderFilters.css';
import { categoriesFilters } from './categoriesFilters';

interface HeaderFiltersProps {
  locationHydration: LocationHydration;
  isNavbarCollapsed?: boolean;
}

function HeaderFilters({ locationHydration, isNavbarCollapsed = false }: HeaderFiltersProps) {
  return (
    <div className={styles.container}>
      <div className={`${styles.filtersContainer} ${isNavbarCollapsed && styles.filtersContainerCollapsed}`}>
        <LocationButton selectedLocation={locationHydration.selectedLocation} variant="primary" isNavbarCollapsed={isNavbarCollapsed} />
        {categoriesFilters.map((category, index) => {
          const { dataCreative } = createPropsToTrackingMatrix(index, {
            trackingPrefix: MATRIX_TYPE.MATRIX,
            matrizTemplate: MATRIX_TYPE.HOME,
            templateType: 'highlight-category',
            cardType: 'collection-card',
            dataLocation: locationHydration.selectedLocation,
          });

          return (
            <a
              href={getMainUrl() + category.link}
              key={category.label}
              className={`${CLASS_TRACKING_GTM.COLLECTION_CARD} ${styles.category}`}
              data-bannerid={category.link}
              data-name={category.label}
              data-creative={dataCreative}
            >
              {category.icon}
              {category.label}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default HeaderFilters;
