import Link from 'next/link';
import dynamic from 'next/dynamic';
import { IconAdd } from '@sympla/sympla-components/ActionIcons';
import { ReactNode, useRef } from 'react';
import IconBuger from 'assets/icons/ic-burguer.svg';
import IconUser from 'assets/icons/ic-user.svg';
import IconCalendar from 'assets/icons/ic-calendar.svg';
import IconTickets from 'assets/icons/ic-ticket.svg';
import IconLogout from 'assets/icons/ic-logout.svg';
import {
  Dropdown, IconFavorite, IconQuestion,
} from '@sympla/sympla-components';
import { getMainUrl, getMainUrlOrganizer, isMobile } from 'utils/misc';
import { PAGES_URL } from 'constants/index';
import { getInitials } from 'utils/getInitials';
import authStore from 'stores/AuthStore';
import * as styles from './UserSpace.css';
import UserSection from '../UserSection/UserSection';

const Button = dynamic(() => import('@sympla/sympla-components/Button'), { ssr: false });

interface NewUserSpaceProps {
  handleLogout: () => void;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean) => void;
  isNavbarCollapsed?: boolean
}

function NewUserSpace({
  handleLogout,
  isDropdownOpen,
  setIsDropdownOpen,
  isNavbarCollapsed = false,
}: NewUserSpaceProps) {
  const authContainerRef = useRef(null);
  const fullName = authStore?.userData?.dataUser.fullName || '';
  const email = authStore?.userData?.dataUser.email || '';

  function buttonAction() {
    if (authStore.isAuthenticated) {
      if (isMobile()) {
        return authStore.setModalAuthenticatedVisible(true);
      }
      return setIsDropdownOpen(true);
    }
    return authStore.openSignInModal();
  }

  const userIcon: ReactNode = authStore.isAuthenticated && fullName
    ? getInitials(fullName)
    : <IconUser width={20} height={20} />;

  return (
    <div className={styles.buttonsContainer}>
      <div className={`${styles.linksContainer} ${isNavbarCollapsed && styles.linksContainerCollapsed}`}>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          id="btn-create-event"
          href={`${getMainUrlOrganizer()}${PAGES_URL.organizerCreateEvent}`}
        >
          <IconAdd />
          Criar evento
        </Link>

        <Link
          className="svg-primary-stroke"
          target="_blank"
          rel="noopener noreferrer"
          id="btn-my-event"
          href={`${getMainUrlOrganizer()}${PAGES_URL.organizerArea}`}
        >
          <IconCalendar />
          Meus eventos
        </Link>

        {
          authStore.isAuthenticated
            ? (
              <Link
                className={`${styles.myTickets} svg-primary-stroke`}
                rel="noopener noreferrer"
                href={`${getMainUrl()}${PAGES_URL.myTickets}`}
                id="btn-my-tickets"
              >
                <IconTickets />
                Meus ingressos
              </Link>
            ) : (
              <Button
                onClick={authStore.openSignInModal}
                variant="text"
                color="neutral"
                upperCase={false}
                icon={<div className="svg-primary-stroke"><IconTickets /></div>}
                id="btn-my-tickets"
              >
                Meus ingressos
              </Button>
            )
        }
      </div>

      <div className={styles.dropdownContainer} ref={authContainerRef}>
        <Dropdown
          topDistance="0px"
          isOpen={isDropdownOpen}
          options={[]}
          onOpenChange={() => setIsDropdownOpen(false)}
          side="bottom"
          container={authContainerRef.current}
          trigger={(
            <button
              type="button"
              className={
                styles.userButtonDropdown[
                  isDropdownOpen ? 'focus' : 'default'
                ]
              }
              onClick={() => buttonAction()}
            >
              {
                isNavbarCollapsed && <span><IconTickets width={20} height={20} /></span>
              }
              <IconBuger width={20} height={20} />
              <div className={styles.avatar}>
                { userIcon }
              </div>
            </button>
          )}
        >
          <div className={styles.customDropdown} style={{ height: `${isDropdownOpen ? '100%' : '0px'}` }}>
            <div className={styles.customDropdownSection.dropdownUserSection}>
              <UserSection avatar={userIcon} email={email} fullName={fullName} />
            </div>
            <div className={styles.customDropdownSection.dropdownLinkSection}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.NEXT_PUBLIC_ORGANIZER_URL}${PAGES_URL.myAccount}`}
                className={`${styles.dropdownLinkItem} svg-primary-stroke`}
              >
                <IconUser width={18} height={18} />
                Minha conta
              </Link>
              <Link href={PAGES_URL.favorite} className={styles.dropdownLinkItem}>
                <IconFavorite width={18} height={18} />
                Favoritos
              </Link>
              <Link
                className={`${styles.dropdownLinkItem} ${styles.mobileOnly}`}
                target="_blank"
                rel="noopener noreferrer"
                href={`${getMainUrlOrganizer()}${PAGES_URL.organizerCreateEvent}`}
              >
                <IconAdd />
                Criar evento
              </Link>

              <Link
                className={`${styles.dropdownLinkItem} ${styles.mobileOnly} svg-primary-stroke`}
                target="_blank"
                rel="noopener noreferrer"
                href={`${getMainUrlOrganizer()}${PAGES_URL.organizerArea}`}
              >
                <IconCalendar />
                Meus eventos
              </Link>
            </div>
            <div className={styles.customDropdownSection.dropdownLinkSection}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={PAGES_URL.helpCenter}
                className={styles.dropdownLinkItem}
              >
                <IconQuestion width={18} height={18} />
                Central de Ajuda
              </Link>
            </div>
            <div className={styles.customDropdownSection.dropdownLinkSection}>
              <button type="button" className={`${styles.dropdownLinkItem} svg-primary-stroke`} onClick={() => handleLogout()}>
                <IconLogout width={18} height={18} />
                Sair
              </button>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

export default NewUserSpace;
