import { useEffect, useState } from 'react';

export function useScroll(awaysCollapsed = false) {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(awaysCollapsed);

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== 'undefined') {
        const hasScroll = window.scrollY > 25;

        if (hasScroll !== isNavbarCollapsed) {
          setIsNavbarCollapsed(hasScroll);
        }
      }
    };

    if (!awaysCollapsed) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    return undefined;
  }, [isNavbarCollapsed, awaysCollapsed]);

  return isNavbarCollapsed;
}
