'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { urlParamsSearch } from 'constants/search';
import useSearchEvent from 'hooks/useSearchEvent';
import useSearchPlaces from 'hooks/useSearchPlaces';
import useSearchProducers from './useSearchProducers';
import useClosedSearchEvent from './useClosedSearchEvent';
import { searchEventStore, searchPlacesStore, searchProducersStore } from '../stores/SearchContentBaseStore';

export function useSearchContentByLocation() {
  const searchEventStore = useSearchEvent();
  const searchPlacesStore = useSearchPlaces();
  const searchProducersStore = useSearchProducers();
  const searchEventClosed = useClosedSearchEvent();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const currentPage = pathname?.split('/')?.[1];
  const tab = searchParams?.get(urlParamsSearch.tab);
  const option = tab || currentPage;

  const searchOptions = {
    eventos: (searchParams?.get('s'))
      ? (query: string) => searchEventStore.fetchData(query)
      : (query: string) => searchEventClosed.fetchData(query),
    locais: (query: string) => searchPlacesStore.fetchData(query),
    produtores: (query: string) => searchProducersStore.fetchData(query),
    default: (query: string) => searchEventStore.fetchData(query),
  };

  return searchOptions[option as keyof typeof searchOptions] || searchOptions.default;
}

// TODO: Remover quando retirar a pagina de /pages
export function useNewSearchContentByLocation() {
  // const searchContentBaseStore = useSearchEvent();
  // const searchPlacesStore = useSearchPlaces();
  // const searchProducersStore = useSearchProducers();
  const pathname = usePathname();
  const currentPage = pathname?.split('/')?.[1];

  const searchOptions = {
    eventos: (query: string) => searchEventStore.fetchData(query),
    locais: (query: string) => searchPlacesStore.fetchData(query),
    produtores: (query: string) => searchProducersStore.fetchData(query),
    default: (query: string) => searchEventStore.fetchData(query),
  };

  return searchOptions[currentPage as keyof typeof searchOptions] || searchOptions.default;
}
