import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B2WyW7bSBBA7%2F6KAnhJADnoqt7I8omk1NcAMx8Q0BZleZNlSfGSQf59UDIymC51hATIUTw%2BsBZWF9GPN4%2BPO%2FjnDOD8%2FEt4Xu52T4aB1q8X%2F0fI4BQihqCQZagVcgyok3kG1KGBgXSFyGB1bM0QnFGwYaithgMDotH0kgH9Ab1i%2BLweV%2FD3sNpOYDustufbcXOzyF6aM%2Fw13I8vw9tPXxkZUOdeFCZwzYD6a5eFqdwwoJ7oLQPpGncMpGvcFyb6wEA636ow5UcGq2PXDFb398TgdOyGwesaW4age94xWGMy9JXBKfQskTl6YYgKvTLUCr0x5OBbYavN4VojHu410uFioy2cK7rCIaIvnCKWFh5LG4%2B1TEXDhsEd5BwY%2FEH1S4ZwkPNKfiMN5wzx4M1RRqvhgsEArl9lomBgc305fCA%2FAYsTcGYC5hP6j3nItYTI6%2FKD%2FCRGhSwlJEgV98tlbvZlpIQsazmIVMwtQ5OTO4ZqDHEkyvk9Q7XAK2fy3x4fGCqKwxjylcMVQ2VMTNOQ88c9b2JKOV9LnrZNmj8xVDOfnOYbyRMb533OtwwVxo5Cm%2FMdQ%2BViP63V936V%2FH2aJpvzZ4Yq1raZzXL%2BwlDV0Xcp5vyVoWpd3aYu528MVfLJ6v6%2FST8ptoT51WIYqhCaRHn%2FhFI39J3tc07v%2BTvVJ1n5LuMS5vMnJzyFmvLzIi95bB2dy3kQPktB54%2F8Y7%2F8BNC5CXhXWkuqGappQ4amOW8ksbG1LjgIb0KIqsHL90ZIHRRd%2FdeImUA0E2hCsY%2B5xO%2BfnI%2FvA4ypzvlC%2Bp7OzEz1d81QdU3X9%2FkPQks5IFf3Tb4AdCMH3fsY1MHdyqI2mKi%2BOPt%2BxuIin%2BbD5u58txwfxpOWnLTkpCUnLTlpyZ%2FXEt93vb6Oj2lJSWNESwySvr32WhL9zLRKD45pSUljREuMt1bpwV5Letv5lN%2BmR7WkpDGiJTRD8oqLlrT9tI%2F5%2FI9qSUljREuwpdqo21C0JMWOospzTEtKGiNa4hF7VO%2F%2FlpakadO16ho%2FpiUljREt8d70qAbwO1piBkTMF3ivJWjJ2OFQS3BOczf%2BupbM56MZ1feIliz8Ii6U9oiWLPbPxdn3fwETbm57IhEAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fnew-components%2FSession%2FSession.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81V227iMBB95ytGlVYtEkaBQIqCttr%2FWK0qE0%2FCbB07dRygXfHvqzhBMeFaaR%2F2zRmPj%2Bccn5mMX6P3bfBGAfwZAHwyUgJ3MYTBcgCQyno9rZeCykLyj9jF6sDvqrSUfrBEK4vKNhsMlVgO9oNxizpxqB5MzndsS8KuY5gHxe4%2B5ASVRePjTh1uC1Rjtrln8PxDq8parUbQRXhToFaWlfSJMWy4eWLsNdqsrX1Ph8vD7hYpW9ve%2Fmbo44cO64QOl5QpRhbzsqMCUOiSLGkVg0HJLW2wjiaVKbWJodB0SDzH0r%2F0p9ESvz%2FkqKr6kodfvjaTIPh2nH4PY4s7ywQm2vCmRKUV9mEaMTu0iwpdv80HHVOiFePG6C0TetuA59xkpJjE1MYwbzyTkpQ9rIluSjdcHYRd8eQtM7pSgiVaagPBOCwBeYkjh9B91yf72T38KTr8lTYCDTNcUFWe6jtzJRdcCFJZDIug2EEAU2f1Lmt%2B%2BkTeoWNas2EvEPYDz8NlJ9NKW6vzPkg0PNtprmUFGUxaJ%2Brt1Qa8T6SOZ%2FQFnos%2BragfWHTedD3l1%2BUcliK3lUFWorWksjKGx0RS9gg6TUfwKCnjbn3NkplnWPshsfa%2Bybm8PQgAJClk63Z%2FOuu%2F%2BQsI2viKhNPFcc7zOb3ah7W6OLXGfytIdUmQr8z7C7PT18sbQq2v2kFxrFRwdQxlR6ZdNCPt4oRp38OcYT0Je0gFt%2BurcPvBjxwFcSgTg6iAKwFP3k9yEQbFbuggup%2FWS0s7TsmUliVrksKleOI2Extg752M2qQLHfh8swObSbO%2FWXU0O1P1HQWG%2F4DF%2FCaL%2BYHFX9JP3on8CAAA%22%7D"
export var LoadingContainer = '_6qw0ki1';
export var authContainer = '_6qw0ki3';
export var buttonsContainer = '_6qw0ki2';
export var container = '_6qw0ki0';
export var dropdownIcon = '_6qw0ki8';
export var favoritesErrorContainer = '_6qw0ki4';
export var loginBoxDescription = '_6qw0ki6';
export var loginBoxFooter = '_6qw0ki7';
export var sectionContainerLoginBox = '_6qw0ki5';