'use client';

import { useCallback, useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import dynamic from 'next/dynamic';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';

import { deleteUrlQueryParam } from 'utils/misc';
import { useSearchContentByLocation } from 'hooks/useSearchContentByLocation';
import { useGetPathWithLocation } from 'hooks/useGetPathWithLocation';
import locationStore from 'stores/LocationStore';
import { searchEventStore } from 'stores/SearchContentBaseStore';
import { ANYWHERE_LOCATION, NEAR_TO_YOU_LABEL } from 'constants/index';
import { citiesCollectionList } from 'constants/citiesCollectionList';
import { urlParamsSearch, urlPathname } from 'constants/search';
import { PageType } from 'constants/enums/search';
import { setLocationCookies } from '../utils/setLocationCookies';

import * as styles from './LocationModal.css';

const TextInput = dynamic(() => import('@sympla/sympla-components/TextInput'), {
  ssr: false,
});

const IconPlace = dynamic<IconProps>(() => import('@sympla/sympla-components/ActionIcons').then((mod) => mod.IconPlace), {
  ssr: false,
});

const IconArrowLocation = dynamic<IconProps>(() => import('@sympla/sympla-components/ActionIcons').then((mod) => mod.IconLocalizationFill), {
  ssr: false,
});

const Loading = dynamic(() => import('components/new-components/Loading/Loading'), {
  ssr: false,
});

const CloseButtonModal = dynamic(() => import('components/new-components/Shared/CloseButtonModal/CloseButtonModal'), {
  ssr: false,
});

function LocationModal() {
  const route = useRouter();
  const pathname = usePathname();
  const getPathWithLocation = useGetPathWithLocation();
  const searchContent = useSearchContentByLocation();

  const fillCitiesDebounced = useCallback(debounce(locationStore.fillCities, 800), []);

  useEffect(() => {
    deleteUrlQueryParam({ param: urlParamsSearch.page });
    locationStore.fillCities();
  }, []);

  return (
    <div className={styles.containerStyle}>
      <CloseButtonModal
        ariaLabel="Fechar modal de localização"
        iconSize={24}
        top={8}
        right={8}
        onClick={() => locationStore.setIsLocationModalVisible(false)}
      />
      <div className={styles.topWrapperStyle}>
        <h1 className={styles.titleStyle}>Localização</h1>
        <TextInput
          id="location"
          type="text"
          // size="sm"
          placeholder="Onde?"
          onChange={(e) => fillCitiesDebounced(e.target.value)}
        />
      </div>
      <button
        type="button"
        className={styles.geolocationButtonStyle}
        onClick={() => {
          locationStore.setIsLocationModalVisible(false);
          locationStore.setIsGeolocationModalVisible(true);
        }}
      >
        <IconArrowLocation width={24} height={24} />
        <div>
          <p>Usar minha localização atual</p>
          <p>Encontre eventos perto de você</p>
        </div>
      </button>
      <div className={styles.citiesWrapperStyle}>
        {locationStore.isFetchingCities ? (
          <Loading />
        ) : (
          locationStore.cities.map((location) => (
            <button
              type="button"
              key={location.slug}
              className={styles.cityButtonStyle}
              onClick={() => {
                const changeCity = locationStore?.selectedLocation?.slug !== location.slug && !!locationStore?.selectedLocation?.slug;
                locationStore.setIsLocationModalVisible(false);
                locationStore.setSelectedLocation(location);
                setLocationCookies({ userLocation: location });
                const query = window?.location?.search;
                const url = getPathWithLocation({ location, changeCity });
                if (url) {
                  if (url.includes(urlPathname.events) && citiesCollectionList.includes(location.slug)) {
                    window.location.href = `${url}${query}`;
                    return;
                  }
                  const noCity = [ANYWHERE_LOCATION, NEAR_TO_YOU_LABEL].includes(location?.city);
                  const reloadCollection = pathname === url && searchEventStore.typePage === PageType.COLLECTION && noCity;
                  if (reloadCollection) {
                    window.location.reload();
                    return;
                  }
                  searchContent(query);
                  route.push(`${url}${query}`);
                }
              }}
            >
              <IconPlace width={20} height={20} />
              <span>{location.city}</span>
            </button>
          ))
        )}
      </div>
    </div>
  );
}

export default observer(LocationModal);
