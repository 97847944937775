'use client';

import Link from 'next/link';
import { IconClose } from '@sympla/sympla-components/NavigationIcons';
import { useState } from 'react';
import { getMainUrlOrganizer } from 'utils/misc';
import * as styles from './MobileCTACreateEventBanner.css';

interface MobileCTABannerProps {
  isMobile?: boolean;
}

export function MobileCTACreateEventBanner({ isMobile = false }: MobileCTABannerProps) {
  const [showBanner, setShowHideBanner] = useState(isMobile);

  if (!showBanner) return null;

  return (
    <div className={styles.mobileCTABanner}>
      <p className={styles.text}>Produza seus eventos aqui!</p>
      <Link
        id="btn-create-event-mobile"
        href={`${getMainUrlOrganizer()}/criar-evento`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.button}
      >
        Crie seu evento
      </Link>
      <button type="button" onClick={() => setShowHideBanner(false)}>
        <IconClose />
      </button>
    </div>
  );
}
