'use client';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable no-console */
import { createRef, useEffect } from 'react';
import { UserData } from 'stores/AuthStore';
import { getBuyerEmailDecodedClient, saveBuyerEmailEncodedClient } from 'utils/login/getUserCookiesInfo';

type SessionProps = {
  activeComponent: number;
  logIn: (payload: any) => Promise<void>;
  signUp: (payload: any) => Promise<void>;
  forgotPsw: () => void;
  hideModal: () => void;
  lang: string;
  modalOpen: boolean;
  onLoadModal?: () => void;
};

interface SymplaAuth {
  onClose: () => void;
  closeModal: () => void;
  onSignIn: (payload: { data: UserData }) => Promise<void>;
  onSignUp: (payload: { data: UserData }) => Promise<void>;
  onForgotPsw: (email: string) => void;
  onError: (error: Error) => void;
  onLoad: () => void;
  openModal: (item: string) => void;
  logout: () => void;
  refreshToken: (token: string, refreshToken: string) => Promise<any>;
  renderComponentInElement: (container: HTMLElement | string, component: string) => void;
  emailDefaultValue: string;
  googleClientId: string;
  facebookClientId: string;
  recaptchaKey: string;
}

function Session({
  activeComponent,
  logIn,
  signUp,
  forgotPsw,
  hideModal,
  lang,
  modalOpen,
  onLoadModal,
}: SessionProps) {
  const symplaAuthRef = createRef<SymplaAuth>();

  function initialCDKLoginWeb() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      // const timestamp = new Date().getTime();
      script.id = 'cdk-login-web';
      script.src = `${process.env.NEXT_PUBLIC_CDK_LOGIN_WEB_URL}/index.js`; // ?timestamp=${timestamp}
      script.defer = true;
      script.onload = () => {
        resolve(true);
      };

      script.onerror = (error) => {
        console.log('LOG ERR(cdk-login-web): ', error);
        reject(error);
      };

      document.head.appendChild(script);
    });
  }

  function getEmailValueFromCookie() {
    const inputValue = getBuyerEmailDecodedClient();
    return inputValue || '';
  }

  async function handleModal(open: boolean) {
    const symplaAuth = symplaAuthRef.current;

    if (open && symplaAuth) {
      const optionList = ['signIn', 'forgotPassword', 'signUp'];

      await customElements.whenDefined('auth-component');
      symplaAuth.openModal(optionList[activeComponent]);
    }
  }

  function onCloseModal() {
    hideModal();

    if ((global as any)?.accountModal) {
      (global as any)?.accountModal.resetAccountModal();
    }
  }

  function updateEmailValueCookie(email: string) {
    saveBuyerEmailEncodedClient(email);
  }

  async function loginMiddleware(payload: { data: UserData }) {
    await logIn(payload.data);
    updateEmailValueCookie(payload.data.dataUser.email);
  }

  function onLoad() {
    onLoadModal?.();
  }

  function onForgotPsw() {
    forgotPsw();
  }

  async function onSignUp(payload: { data: UserData }) {
    await signUp(payload.data);
  }

  async function onError(error: any) {
    let extraData = error.data;
    const socialFunctionsName = [
      'googleSignin',
      'googleSignup',
      'facebookSignin',
      'facebookSignup',
      'facebookSigninLegacy',
      'getFacebookAccessToken',
    ];
    const authComponentsMethods = ['signin', 'signup', 'forgotPassword'];

    if (authComponentsMethods.includes(error.functionName)) {
      extraData = {
        email: error.email ? String(error.email) : '',
        response: JSON.stringify(error.data),
        response1: error.stack,
        response2: String(error.data),
      };
    } else if (socialFunctionsName.includes(error.functionName)) {
      extraData = {
        response: JSON.stringify(error.data),
        response1: error.stack,
        response2: String(error.data),
      };
    }

    if (error.hasAccountNonActived) {
      extraData = {
        message: 'Account Non-Actived',
        urlAPI: `${process.env.REDIRECT_BASE_URL}/access/login`,
        email: error.email ? String(error.email) : '',
        response: JSON.stringify(error.data),
        response1: error.stack,
        response2: String(error.data),
      };
    }

    if (error.failedLoginLegacy) {
      extraData = {
        message: 'Trying login in legacy API',
        urlAPI: `${process.env.REDIRECT_BASE_URL}/access/login`,
        email: error.email ? String(error.email) : '',
        response: JSON.stringify(error.data),
        response1: error.stack,
        response2: String(error.data),
      };
      console.log('LOG ERR(cdk-login-web): ', error, extraData);
    }
  }

  useEffect(() => {
    const alreadyLoaded = document.getElementById('cdk-login-web');
    if (!alreadyLoaded) initialCDKLoginWeb();
  }, []);

  useEffect(() => {
    const symplaAuth = symplaAuthRef.current;

    if (symplaAuth) {
      symplaAuth.onClose = onCloseModal;
      symplaAuth.onSignIn = loginMiddleware;
      symplaAuth.onSignUp = onSignUp;
      symplaAuth.onForgotPsw = onForgotPsw;
      symplaAuth.onError = onError;
      symplaAuth.onLoad = onLoad;
      symplaAuth.emailDefaultValue = getEmailValueFromCookie();

      symplaAuth.googleClientId = process.env.NEXT_PUBLIC_CLIENT_ID_GOOGLE as string;
      symplaAuth.facebookClientId = process.env.NEXT_PUBLIC_APP_ID_FACEBOOK as string;
      symplaAuth.recaptchaKey = process.env.NEXT_PUBLIC_KEY_RECAPTCHA as string;

      window.symplaAuthComponent = symplaAuth;
    }
  }, [symplaAuthRef]);

  useEffect(() => {
    handleModal(modalOpen);
  }, [modalOpen]);

  return (
    <auth-component
      ref={symplaAuthRef}
      id="symplaAuth"
      lang={lang}
      app-name="discovery-next"
      googleClientId={process.env.NEXT_PUBLIC_CLIENT_ID_GOOGLE}
      facebookClientId={process.env.NEXT_PUBLIC_APP_ID_FACEBOOK}
      recaptchaKey={process.env.NEXT_PUBLIC_KEY_RECAPTCHA}
      environment={process.env.NEXT_PUBLIC_CLOUD_FRONT_BUNDLE_ENVIRONMENT}
      style={{ width: 0 }}
    />
  );
}

export default Session;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'auth-component': any;
    }
  }
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    symplaAuthComponent: SymplaAuth;
  }
}
