'use client';

import Link from 'next/link';
import { LocationHydration } from 'stores/LocationStore';
import Image from 'next/legacy/image';
import { Authentication } from 'components/new-components/Session';
import { useScroll } from 'hooks/useScroll';
import NewHeaderContext from '../NewHeaderContext/NewHeaderContext';

import * as styles from './Navbar.css';
import HeaderFilters from '../NewHeaderContext/components/HeaderFilters/HeaderFilters';
import { MobileCTACreateEventBanner } from '../MobileCTACreateEventBanner/MobileCTACreateEventBanner';

interface NavbarProps {
  userId: number | null
  locationHydration: LocationHydration,
  awaysCollapsed?: boolean
  isMobile?: boolean
}

function Navbar({
  userId,
  locationHydration,
  awaysCollapsed = false,
  isMobile = false,
}: NavbarProps) {
  const isNavbarCollapsed = useScroll(awaysCollapsed);

  return (
    <nav className={styles.navbar}>
      <MobileCTACreateEventBanner isMobile={isMobile} />
      <div className={`${styles.container} ${isNavbarCollapsed && styles.containerCollapsed} ${isMobile && styles.containerMobile}`}>
        <div className={`${styles.wrapper} ${isNavbarCollapsed && styles.wrapperCollapsed}`}>
          <Link href="/" aria-label="Sympla sua plataforma de eventos. Logo." title="Sympla sua plataforma de eventos">

            <div hidden={!isNavbarCollapsed}>
              <Image
                key="collapsedImage"
                priority
                src="/discovery-assets/icons/logo-sympla-minimal.svg"
                height={38}
                width={38}
                alt="Sympla sua plataforma de eventos"
              />
            </div>
            <div hidden={isNavbarCollapsed}>
              <Image
                key="defaultImage"
                hidden={isNavbarCollapsed}
                priority
                src="/discovery-assets/icons/logo-sympla.svg"
                height={38}
                width={114}
                alt="Sympla sua plataforma de eventos"
              />
            </div>

          </Link>
          <div className={`${styles.filtersContainer} ${isNavbarCollapsed && styles.filtersContainerCollapsed}`}>
            <NewHeaderContext locationHydration={locationHydration} isNavbarCollapsed={isNavbarCollapsed} />
          </div>
          <div style={{ zIndex: 1 }}>
            <Authentication isAuthenticated={!!userId} isNavbarCollapsed={isNavbarCollapsed} />
          </div>
        </div>
        {
        isNavbarCollapsed && (
          <div className={styles.headerFiltersContainer}>
            <HeaderFilters locationHydration={locationHydration} isNavbarCollapsed />
          </div>
        )
      }
      </div>
    </nav>
  );
}

export default Navbar;
