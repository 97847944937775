export function getInitials(name: string) {
  if (!name) return '';
  const splitName = name.trim().split(' ').filter(Boolean);

  if (splitName.length === 0) return '';

  if (splitName.length === 1) {
    return splitName[0].substring(0, 2).toUpperCase();
  }
  return (splitName[0][0] || '') + (splitName[1]?.[0] || '').toUpperCase();
}
