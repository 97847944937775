'use client';

import FooterComponent from '@sympla/sympla-components/Footer';

function Footer() {
  return (
    <FooterComponent lang="pt" />
  );
}

export default Footer;
