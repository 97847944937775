/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

'use client';

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import authStore, { UserData } from 'stores/AuthStore';
import { Spinner } from '@sympla/sympla-components';

import { getInitials } from 'utils/getInitials';
import * as styles from './Session.css';
import NewUserSpace from './UserSpace/UserSpace';

const Session = dynamic(() => import('./Session'), { ssr: false });
const ModalAuthenticated = dynamic(() => import('./MenuMobileAuthenticated/MenuMobileAuthenticated'), { ssr: false });

function Authentication({ isAuthenticated, isNavbarCollapsed = false }: { isAuthenticated: boolean, isNavbarCollapsed?: boolean}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function handleLogout() {
    window.symplaAuthComponent.logout();
    authStore.logOut();
    setIsDropdownOpen(false);
  }

  useEffect(() => {
    authStore.isAuthenticated = isAuthenticated;
    authStore.getSession();
    authStore.setIsRecoveringSession(false);
  }, [isAuthenticated]);

  async function onHandleAuth(userData: UserData) {
    const checkSession = document.querySelector('auth-component #keepMeConnected') as HTMLInputElement;
    const shouldKeepSession = checkSession?.checked;
    authStore.updateUserData(userData, shouldKeepSession);
  }

  if (authStore.isRecoveringSession) {
    return <Spinner className={styles.LoadingContainer} />;
  }

  return (
    <div className={`${styles.container} animated fadeIn`}>
      <NewUserSpace
        isNavbarCollapsed={isNavbarCollapsed}
        handleLogout={handleLogout}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={(value: boolean) => setIsDropdownOpen(value)}
      />
      <Session
        activeComponent={authStore.modalType}
        modalOpen={authStore.isModalOpen}
        forgotPsw={authStore.openForgotPswModal}
        hideModal={authStore.onCloseModal}
        lang="pt"
        logIn={onHandleAuth}
        signUp={onHandleAuth}
      />
      {authStore?.userData?.token && (
        <ModalAuthenticated
          name={authStore.userData.dataUser.fullName}
          nameInitials={getInitials(authStore.userData.dataUser.fullName)}
          email={authStore.userData.dataUser.email}
          isModalAuthenticatedVisible={authStore.isModalAuthenticatedVisible}
          closeModalAuthenticated={() => authStore.setModalAuthenticatedVisible(false)}
          handleLogout={() => handleLogout()}
        />
      )}

    </div>
  );
}

export default observer(Authentication);
