'use client';

import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter, useSearchParams } from 'next/navigation';

import useSearch from 'hooks/useSearch';
import { Location } from 'location';
import Dropdown from '@sympla/sympla-components/Dropdown';
import EventCard from '@sympla/sympla-components/EventCard';

import { createPropsToTrackingLiveSearch } from 'components/Integration/createPropsToTrackingMatrix';
import { Loading } from 'components/new-components/Loading';
import { searchEmptyList } from 'constants/searchEmptyList';
import { createLinkToRedirect } from 'utils/createLinkToRedirect';
import { convertObjectToQueryParam } from 'utils/misc';

import locationStore from 'stores/LocationStore';
import Link from 'next/link';
import { IconTime } from '@sympla/sympla-components';
import * as styles from './SearchList.css';

const Button = dynamic(() => import('@sympla/sympla-components/Button'), { ssr: false });
const SearchItem = dynamic(() => import('./SearchItem'), { ssr: false });

type SearchListDropdownProps = {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean) => void;
  options: { value: string; tab?: string }[];
  text: string;
  children?: React.ReactNode;
  form: HTMLFormElement | null;
  userLocation: Location;
  handleShowMore: () => void;
  lastSearches: string[];
  updateLastSearches: (text: string) => void;
};

function SearchEmptyList({
  userLocation,
  closedDropdown,
  lastSearches,
  updateLastSearches,
}: {
  userLocation: Location;
  closedDropdown: () => void;
  lastSearches: string[];
  updateLastSearches: (text: string) => void
}) {
  const [lastSearchesItems, setLastSearchesItems] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    const items = lastSearches?.map((text) => {
      const query = convertObjectToQueryParam({
        s: text,
      });
      return (
        <Link
          key={text}
          onClick={() => { closedDropdown(); updateLastSearches(text); }}
          className={styles.lastSearchesText}
          href={createLinkToRedirect({
            param: query,
            routeType: 'search-bar-default-filter',
            userLocation,
          })}
        >
          <IconTime width={20} height={20} />
          {text}
        </Link>
      );
    });
    setLastSearchesItems(items);
  }, [lastSearches]);

  return (
    <>
      <p className={styles.searchListTitle}>Sugestões</p>
      <div className={styles.searchEmptyListContainer}>
        {searchEmptyList?.map((item) => (
          <Link
            className={styles.searchDefaultText}
            href={createLinkToRedirect({
              item: {
                date: item?.date,
                urn: '',
              },
              param: `?d=${item?.param}`,
              routeType: 'search-bar-default-filter',
              userLocation,
            })}
            onClick={() => closedDropdown()}
            aria-label={item.label}
            key={item.label}
          >
            {`${userLocation?.slug ? `Em ${userLocation?.city}` : ''} ${item.label}`}
          </Link>
        ))}
      </div>
      {lastSearchesItems.length > 0 && <p className={styles.searchListTitle}>Pesquisas recentes</p>}
      {lastSearchesItems}
    </>
  );
}

const SearchResults = observer(
  ({
    options,
    text,
    userLocation,
    handleShowMore,
    closedDropdown,
    updateLastSearches,
  }: {
    options: { value: string; tab?: string }[];
    text: string;
    userLocation: Location;
    handleShowMore: () => void;
    closedDropdown: () => void;
    updateLastSearches: (text: string) => void;
  }) => {
    const searchStore = useSearch();
    const searchParams = useSearchParams();
    const router = useRouter();
    const [searchItems, setSearchItems] = useState<React.ReactNode[]>([]);

    useEffect(() => {
      const items = options.map(({ value, tab }) => {
        const query = convertObjectToQueryParam({
          s: text,
          tab,
        });
        return (
          <SearchItem
            updateLastSearches={updateLastSearches}
            key={value}
            value={value}
            text={text}
            userLocation={userLocation}
            params={query}
            closedDropdown={closedDropdown}
          />
        );
      });
      setSearchItems(items);
    }, [options, text, searchParams, router]);

    return (
      <div className={`${styles.searchResultsContainer} ${text ? styles.searchResultsContainerPadding : ''}`}>
        {searchItems}
        {searchStore.isFetching && <Loading isNotFullScreen />}
        {searchStore.searchEventResult.length > 0 && !searchStore.isFetching && (
          <div className={styles.animatedContainer}>
            {searchStore.searchEventResult.map((event, idx) => (
              <div className={styles.EventCardContainer} key={`${event.id}-${event.name}`}>
                <EventCard
                  key={event.id}
                  hasPriority={!idx}
                  isLateralized
                  {...event}
                  fullWidth
                  isHover={false}
                  {...createPropsToTrackingLiveSearch(idx, event, {
                    trackingPrefix: 'search',
                    dataLocation: locationStore.selectedLocation,
                    type: 'event-card',
                  })}
                />
              </div>
            ))}
          </div>
        )}
        {!searchStore.isFetching && (
          <div className={styles.SearchButtonResult}>
            <Button variant="text" onClick={handleShowMore}>
              Ver todos os eventos
            </Button>
          </div>
        )}
      </div>
    );
  },
);

function SearchListDropdown({
  isDropdownOpen,
  setIsDropdownOpen,
  options,
  text,
  children,
  form,
  userLocation,
  handleShowMore,
  lastSearches,
  updateLastSearches,
}: SearchListDropdownProps) {
  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const closedDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      onOpenChange={() => setIsDropdownOpen(true)}
      options={[]}
      topDistance="10px"
      trigger={children}
      side="bottom"
      container={form}
    >
      <div
        className={styles.searchListDropdownContainer}
        onMouseDown={handleMouseDown}
        role="menuitem"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleShowMore();
          }
        }}
      >
        {!text ? (
          <SearchEmptyList
            userLocation={userLocation}
            closedDropdown={closedDropdown}
            lastSearches={lastSearches}
            updateLastSearches={updateLastSearches}
          />
        ) : (
          <SearchResults
            updateLastSearches={updateLastSearches}
            options={options}
            text={text}
            userLocation={userLocation}
            handleShowMore={handleShowMore}
            closedDropdown={closedDropdown}
          />
        )}
      </div>
    </Dropdown>
  );
}

export default SearchListDropdown;
